* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure {
  padding: 0px;
  margin: 0px;
}

html,
body {
  overflow-x: hidden;
  background-color: #e1e2e56b;
}

.cust_container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px
}



/*Header Start  */

.main_header {
  background-color: transparent;
  width: 100%;
  height: 75px;
  padding: 10px 0px 10px;
  position: absolute;
  z-index: 99;
  display: flex;
  align-items: center;
}

.hdbkpt {
  background-color: #000;
  width: 100%;
}

.main_header.fixed {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  right: 0;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #000;
}

.nvbsr {
  display: none;
}

.nav-elements {
  display: flex;
  width: 100%;
  justify-content: center;
}

.logo_mnu_br {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li {
  margin-right: 25px;
}

.hdicon i {
  color: #fff;
  margin-right: 10px;
  background-color: #FF9C00;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
}

.hdicon:hover {
  text-decoration: none;
}


.lghdiv {
  min-width: 58px;
  /* height: 26px; */
  height: auto;
  width: 100px;
}

.lghdiv img {
  width: 100%;
  height: 100%;
}

.nav-elements ul a {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #ffffff;
}

.nav-elements ul a:hover {
  text-decoration: none;
}

.logo_menu_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mnu_icn a i {
  margin-left: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.main_header .logo_hd_mn {
  width: 100%;
    max-width: 10%;
    min-width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
.main_header .logo_hd_mn:hover{
  text-decoration: none;
}

.main_header .lghdiv {
  width: 100%;
  max-width: 20%;
}
.foter_main .logo_hd_mn {
  width: 100%;
  max-width: 38%;
  min-width: 34%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.foter_main .logo_hd_mn:hover{
  text-decoration: none;
}

.foter_main .lghdiv {
  width: 100%;
  max-width: 25%;
}
.bookAppoSection .logo_hd_mn {
  width: 100%;
  max-width: 20%;
  min-width: 19%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bookAppoSection .logo_hd_mn:hover{
  text-decoration: none;
}

.bookAppoSection .lghdiv {
  width: 100%;
  max-width: 25%;
}

.deshtxt {
  font-size: 18px;
  white-space: nowrap;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(88deg, rgba(250, 166, 50, 1) 0%, rgba(0, 166, 144, 1) 20%, rgb(24 134 223) 43%, rgba(215, 65, 73, 1) 97%);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 900;
  font-family: 'Playfair Display', serif;
  margin-left: 10px;
  /* margin-bottom: 10px; */
}


/* .mnu_icn:hover a i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
} */

.sub_menu {
  top: 0px;
  /* opacity: 0; */
  min-width: 270px;
  z-index: 999;
  position: absolute;
  visibility: visible;
  padding: 10px 0px;
  background-color: #000000;
  transform: translateY(40px);
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0px 5px 0px #000000;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 10px;
}

.sub_menu li {
  padding-bottom: 15px;
}



.mnu_icn {
  position: relative;
}

.bkdhddivbtn {
  background-color: #ffffff;
  padding: 8px;
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  transition: all 0.4s ease-in-out;
  z-index: 1;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  border: 1px solid #19313e;
  padding: 8px 20px;
}

.bkdhddivbtn:hover::before {
  left: 0;
  right: auto;
  transform: scaleX(1);
  transform-origin: left;
}

.bkdhddivbtn::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  transform: scaleX(0);
  transform-origin: right;
  background: #19313e;
  transition: transform 0.5s ease 0s;
  /* border: 1px solid #fff; */
  z-index: -1;

}

.bkdhddivbtn:hover {
  text-decoration: none;
  color: #fff;
}

/* Header End */

/* Top Header start*/
.top_header {
  background-color: #19313e;
  width: 100%;
  height: auto;
  padding: 5px;
}

.tphdrdiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tphdrdivul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tphdrdivul li.tphdrdivli {
  list-style-type: none;
  color: #fff;
}

.tphdrdivul li.tphdrdivli a {
  color: #fff;
  font-family: 'Manrope', sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;

}

.tphdrdivul li.tphdrdivli a:hover {
  text-decoration: none;
}

.tphdrdivul li.tphdrdivli i {
  margin-right: 10px;
}

/* Top Heder End */

/* subscribeLetter Start */
.sbscrb_mainimg {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe_main_section {
  padding-top: 20px;
  padding-bottom: 30px;
}

.bg_overlay {
  width: 100%;
  height: 100%;
  background-color: #00000075;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 75%;
  margin: 30px;
  max-height: 85%;
}

.srcvgdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.txtsbscrd {
  color: #fff;
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 1px;
  padding-bottom: 20px;
  font-family: 'Playfair Display', serif;
}

.txtsbscrdpp {
  color: #fff;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  padding-bottom: 20px;
  width: 100%;
  max-width: 800px;
  text-align: center;
}

/* subscribeLetter End */
/* Footer Start */
.ftr_logo_para_cntnt {
  width: 100%;
  max-width: 30%;


}

.imgrtn_flx_txt_auick {
  width: 100%;
  max-width: 22%;
}

.imgrtn_flx_txt_flw {
  width: 100%;
  max-width: 15%;
}

.imgrtn_flx_txt_info {
  width: 100%;
  max-width: 18%;
}

.ftr_txtppp {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Manrope', sans-serif;
  color: #FFF;
  line-height: 18px;
  text-transform: uppercase;
  padding-bottom: 8px;
  text-decoration: underline;
}

.phtftr {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Manrope', sans-serif;
  color: #FFF;
  line-height: 18px;
}

.imgrtn_flx_txt_info ul li.phtftr {
  cursor: pointer;
  color: #a7a5a5;
}

.imgrtn_flx_txt_info ul li.phtftr:hover {
  color: #c04854;
}

.foter_main {
  background-color: #19313e;
  width: 100%;
  height: auto;
  padding: 60px 0px 60px 0px;
}

.ftr_txt h4 {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  line-height: 42px;
  font-family: 'Manrope', sans-serif;
  padding-top: 20px;
  padding-bottom: 10px;
}

.ftr_txt p {
  font-size: 17px;
  font-weight: 600;
  color: #a7a5a5;
  line-height: 26px;
  font-family: 'Manrope', sans-serif;
  width: 100%;
  max-width: 430px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.ftr_txt p:hover {
  color: #c04854;
}

.ftr_flx {
  display: flex;
  justify-content: space-between;
}

.ftr_txtppp h5 {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  color: #fff;
  line-height: 36px;
  font-family: 'Manrope', sans-serif;
}

.ftr_txtppp p {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  color: #fff;
  line-height: 26px;
  font-family: 'Manrope', sans-serif;
  padding-top: 15px;
  padding-bottom: 10px;
}

.ftr_lnk {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 4%;
  padding-top: 15px;
  padding-bottom: 10px;
}

.ftr_lnk li {
  text-decoration: none;
  list-style-type: none;
}

.ftr_lnk li i {
  font-size: 30px;
  color: #fff;
}

.ftr_txt_dtl p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  line-height: 24px;
  width: 100%;
  max-width: 400px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.ftr_email_mn {
  border: 2px solid #fff;
  width: 100%;
  outline: 0;
  max-width: 450px;
  position: relative;
  border-radius: 4px;
}

.email_send {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  max-width: 80px;
  padding: 11px;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  text-align: center;
}

.ftr_email_mn input {
  width: 100%;
  background-color: transparent;
  border: none;
  /* padding: 15px 80px 0px; */
  /* max-width: 380px; */
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  /* width: 100%; */
  padding-right: 95px;
  height: 45px;
  padding-left: 15px;
}

.ftr_email_mn input:focus {
  outline: none;
  border: none;
}

.ftr_email_mn ::placeholder {
  color: rgb(255, 255, 255);
  font-size: 15px;
}

/* .imgrtn_flx_ftr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3%;
  padding-top: 15px;

} */

.imgrtn_flx_ftr ul li {
  list-style-type: none;
  padding-bottom: 15px;
  padding-top: 4px;


}

.imgrtn_flx_ftr li a {
  color: #a7a5a5;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  font-family: 'Manrope', sans-serif;
}

.imgrtn_flx_ftr li a:hover {
  color: #c04854;
  text-decoration: none;
}

.imgrtn_flx_txt p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  font-family: 'Manrope', sans-serif;
  /* padding-top: 5px; */
  padding-bottom: 5px;

}

.ftr_dtl_lst_eml_ph {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2%
}

.ftr_dtl_lst_eml_ph p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  font-family: 'Manrope', sans-serif;
}

.imgrtn_flx_ftr span.ftr_itm_cnt {
  margin-left: 10px;
}

.scrolltop_btn {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 9;
}

.scrolltop_btn .btn {
  padding: 5px 5px;
  font-size: 20px;
  background-color: #00b9cc;
  color: #fff;
  text-align: center;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: none;
  position: relative;

}



/* .vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
} */
/* Footer End */

@media(max-width:1399px) {


  .ftr_txt_dtl p {
    font-size: 15px;
    line-height: 22px;
  }

  .ftr_txtppp p {
    font-size: 16px;
    line-height: 24px;
  }

  .ftr_txtppp h5 {
    font-size: 22px;
    line-height: 28px;
  }

  .ftr_txt h4 {
    font-size: 24px;
    line-height: 30px;
  }


  .ftr_txt p {
    font-size: 15px;
    line-height: 22px;
  }

  .imgrtn_flx_ftr li a {
    font-size: 14px;
    line-height: 20px;
  }

  .imgrtn_flx_txt p {
    font-size: 14px;
    line-height: 20px;
  }

  .ftr_dtl_lst_eml_ph p {
    font-size: 15px;
    line-height: 21px;
  }
}
@media(max-width:1199px){
  .main_header .lghdiv {
    width: 100%;
    max-width: 27%;
}

.nav-elements ul a {
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  color: #ffffff;
}
.nav-elements ul li {
  margin-right: 15px;
}
}
@media(max-width:992px) {
  .nav-elements {
    position: fixed;
    right: 0;
    top: 0;
    background-color: #FF9C00;
    height: 100%;
    width: 0px;
    transition: all 0.3s ease-in;
    padding-top: 55px 10px;
    z-index: 99;
  }

  .sub_menu {
    min-width: 185px;
    position: relative;
    padding-top: 0px !important;
  }

  .nav-elements.active {
    width: 215px;
  }

  .nav-elements ul {
    display: block;
    padding-left: 15px;
    padding-top: 30px;
    padding-right: 15px;
    height: 100%;
    overflow-y: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .nav-elements ul::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .nav-elements ul {
    -ms-overflow-style: none;
    /* IE and Edge */

  }

  .sub_menu {
    background-color: #FF9C00;
    box-shadow: none;
  }

  .nav-elements ul li {
    margin-right: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
  }

  .nav-elements ul li:last-child {
    border: 0;
  }

  .sub_menu li {
    padding-bottom: 5px;
  }

  .nvbsr {
    display: block;
    position: absolute;
    z-index: 99;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .nvbsr i {
    color: #FFF;
    font-size: 20px;
  }

  .logo_menu_main {
    display: flex;
    justify-content: flex-start;
  }

  .nav-elements {
    display: block;
  }

  .nvbiu {
    padding-top: 10px;
  }

  .bkdhddivbtn {
    display: none;
  }

  /* 
  .nav-elements ul li:not(:last-child) {
    margin-right: 13px;
  } */


}

@media (max-width: 991px) {
  .sub_menu {
    transform: translateY(0);
  }
}

@media(max-width:768px) {
  .ftr_flx {
    flex-wrap: wrap;
    width: 100%;

  }

  .ftr_logo_para_cntnt {
    width: 100%;
    max-width: 100%;
  }

  .ftr_txt p {
    width: 100%;
    max-width: 100%;
    padding-top: 5px;
    padding-bottom: 10px;
  }


  .imgrtn_flx_txt_auick {
    width: 100%;
    max-width: 30%;
  }

  .imgrtn_flx_txt_flw {
    width: 100%;
    max-width: 30%;
  }

  .imgrtn_flx_txt_info {
    width: 100%;
    max-width: 30%;
  }

  .top_header {
    display: none;
  }
}

@media(max-width:577px) {
.main_header .logo_hd_mn {
    width: 100%;
    max-width: 80%;
    min-width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
  }
  .nvbsr i {
    color: #FFF;
    font-size: 17px;
}

  .deshtxt {
    font-size: 19px;
  }

  .banner_sld {
    position: relative;
    height: 220px;
    width: 100%;
  }


  .ftr_flx {
    flex-wrap: wrap;
  }

  .ftr_logo_para_cntnt {
    width: 100%;
    max-width: 100%;
  }

  .ftr_logo_hh_qcklnk {
    width: 100%;
    max-width: 100%;
  }

  .imgrtn_flx_txt_auick {
    width: 100%;
    max-width: 50%;
  }

  .imgrtn_flx_txt_flw {
    width: 100%;
    max-width: 30%;
  }

  .imgrtn_flx_txt_info {
    width: 100%;
    max-width: 65%;
  }

  .scrolltop_btn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999;
  }

  .scrolltop_btn .btn {
    padding: 5px 5px;
    font-size: 16px;
    height: 33px;
    width: 33px;
  }
}

@media(max-width:481px) {
  /* .logo_hd_mn {
    height: 37px;
    width: 94px;
  } */

  .imgrtn_flx_txt_flw {
    width: 100%;
    max-width: 40%;
  }

  .imgrtn_flx_txt_auick {
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 479px) {
  .main_header {
    height: 60px;
  }

.main_header .logo_hd_mn {
    width: 100%;
    max-width: 38%;
    min-width: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
  }

  .deshtxt {
    font-size: 15px;
  }
}
