.timeZoneModal {
    width: 100%;
    height: 100vh;
    background-color: #0000007e;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.innerTimeZoneModal {
    max-width: 620px;
    width: 100%;
    padding: 50px 80px;
    background-color: #fff;
    border-radius: 5px;
}

.sltTimeHead {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin: 0 0 10px 0;
}

.selectInpZone {
    width: 100%;
    font-size: 17px;
    font-weight: 500;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.selectInpZoneDiv {
    margin-bottom: 20px;
}

.doneBtnTIme {
    width: 100%;
    padding: 8px 0;
    border: 0;
    background-color: #005fe5ea;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}

.doneBtnTIme:hover {
    background-color: #005FE5;
}

@media (max-width: 767px) {
    .timeZoneModal {
        padding: 0 20px;
    }

    .innerTimeZoneModal {
        padding: 50px;
    }

    .sltTimeHead {
        font-size: 20px;
    }
}

@media (max-width: 575px) {
    .sltTimeHead {
        font-size: 17px;
    }

    .innerTimeZoneModal {
        padding: 40px;
    }
}

@media (max-width: 479px) {
    .sltTimeHead {
        font-size: 13px;
    }

    .selectInpZone {
        font-size: 14px;
        padding: 5px 10px;
    }

    .selectInpZoneDiv {
        margin-bottom: 10px;
    }

    .doneBtnTIme {
        padding: 6px 0;
        font-size: 15px;
    }

    .timeZoneModal {
        padding: 0 10px;
    }

    .innerTimeZoneModal {
        padding: 20px;
    }
}