.contctbrdrdiv{
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #19313e;
    border-top: 4px solid #19313e;
    margin-top: 20px;

}
.cntntemldiv{
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}
.cntnstenvlp{
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffc920;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 14px;
}
.cntntaddpp{
    font-size: 15px;
    font-weight: 600;
    color: #000;
    font-family: 'Manrope', sans-serif;
    line-height: 22px;
    padding-bottom: 12px;
}
.emllgh{
    font-size: 15px;
    font-weight: 600;
    color: #000;
    font-family: 'Manrope', sans-serif;
}
.phdivtxt{
    font-size: 15px;
    font-weight: 600;
    color: #000;
    font-family: 'Manrope', sans-serif;
}
.mapgghh{
    padding-top: 20px;
   width: 100%;
}
.mapgghh iframe{
    width: 100%;
}