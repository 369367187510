/* Home banner start */
.banner_sld {
  height: 550px;
  width: 100%;
  position: relative;
}

.banner_sld img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner_sid_Overlay {
  width: 100%;
  height: 100%;
  background-color: #00000086;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

p.lorText {
  color: #fff;
  font-size: 26px;
  font-weight: 800;
  line-height: 39px;
  width: 100%;
  max-width: 905px;
  font-family: "Manrope", sans-serif;
  /* text-align: center; */
}

.textheadimgbaner {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  line-height: 60px;
  width: 100%;
  max-width: 666px;
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin-bottom: 15px;
  text-transform: uppercase;
}

p.call_txt {
  color: #fff;
  font-size: 25px;
  font-weight: 800;
  line-height: 43px;
  width: 100%;
  max-width: 770px;
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.iccn i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: #ff9c00;
}

.bktanttdy {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: #fff;
  background: transparent;
  border: 1px solid #ff9c00;
}

.bktanttdy:hover::before {
  left: 0;
  right: auto;
  transform: scaleX(1);
  transform-origin: left;
}

.bktanttdy::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  transform: scaleX(0);
  transform-origin: right;
  background: #ff9c00;
  z-index: -3;
  transition: transform 0.5s ease 0s;
}

.bktanttdy:hover {
  text-decoration: none;
  color: #fff;
}

.bktanttdyDiv {
  display: inline-block;
  position: relative;
  margin: 12px 0;
}

.bannerCustInDiv {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.banner_dtls_txt {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* margin: auto; */
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 10px;
}

/* .banner_sld .slick-prev {
    top: 68%;
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 9;
    left: 90.58%;
}

.banner_sld .slick-next {
    top: 80%;
    line-height: 0;
    position: absolute;
    padding-top: 11px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 9;
    right: 8%;
} */

/* .banner_sld .slick-prev:before {
    content: '\f061';
    font-family: FontAwesome !important;
    font-size: 18px !important;
    line-height: 1;
    opacity: 1 !important;
    color: #FF9C00;
    display: flex;
    background-color: #ffffff;
    border-radius: 30px;
    width: 43px;
    height: 43px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
} */

/* .banner_sld .slick-prev:before:hover{
    background-color: #ffffff;
  } */
/* .banner_sld .slick-next:before {
    content: '\f060';
    font-family: FontAwesome !important;
    font-size: 18px !important;
    line-height: 1;
    opacity: 1 !important;
    color: rgb(255, 255, 255) !important;
    display: flex;
    background-color: #FF9C00;
    border-radius: 30px;
    width: 43px;
    height: 43px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
} */

/* 
.banner_sld .slick-prev {
  left: 48px !important;
}

.banner_sld .slick-next {
  left: 178px !important;
} */

/* Home Banner End */

/* Home Service Start */
.srvc_sct_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2%;
  padding-top: 30px;
}

.srvc_main_section {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: auto;
}

.srvc_box_main {
  width: 100%;
  max-width: 23%;
  /* margin-right: 15px; */
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  margin-bottom: 25px;
}

.srvctxtppp {
  font-size: 20px;
  text-align: center;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 50px;
}

.srvcndiv {
  padding: 20px 40px 9px 40px;
}

.srvc_main_section .slick-slide {
  padding: 0 10px;
}

.srvc_img {
  width: 122px;
  height: 120px;
  /* overflow: hidden; */
  transition-property: all;
  margin: auto;
}

.srvc_img img {
  width: 100%;
  height: 100%;
  object-position: center;
  transition-property: all;
  transition-duration: 500ms;
}

.srvc_img:hover img {
  transform: scale(1.2, 1.2);
}

.srvcd_txtr {
  padding: 10px;
}

.srvcd_txtr h4 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  line-height: 27px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 68px;
}

.srvcd_txtr p {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Manrope", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  /* height: 86px; */
  color: #000;
}

.mre_btn {
  background-color: #ffffff;
  padding: 8px;
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  max-width: 65%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 1;
  overflow: hidden;
  position: relative;
  border: 1px solid #ff9c00;
}

.mre_btn:hover::before {
  left: 0;
  right: auto;
  transform: scaleX(1);
  transform-origin: left;
}

.mre_btn::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  transform: scaleX(0);
  transform-origin: right;
  background: #ff9c00;
  transition: transform 0.5s ease 0s;
  /* border: 1px solid #fff; */
  z-index: -1;
}

.mre_btn:hover {
  text-decoration: none;
  color: #fff;
}

.abt_main_txt h4 {
  font-size: 26px;
  padding-top: 18px;
  padding-bottom: 10px;
  font-weight: 600;
  line-height: 28px;
}

.abt_main_txt p {
  font-size: 15px;
  padding-top: 18px;
  padding-bottom: 10px;
  font-weight: 600;
  line-height: 28px;
}

.mre_btn_divv {
  /* display: inline-block; */
  position: relative;
  margin: 15px 0;
}

.immigratedivmain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.srvc_box_divmnn {
  width: 100%;
  max-width: 90%;
}

/* Home Service End */

/* Home About Start */
.about_main_section {
  padding-top: 40px;
  padding-bottom: 40px;
}
.textsjt {
  font-size: 15px;
  font-weight: 800;
}

.tstsdivmain {
  padding-top: 20px;
}

.abt_div_txt {
  position: relative;
  display: inline-block;
  font-size: 30px;
  line-height: 26px;
  font-weight: 600;
  /* padding-bottom: 9px;
    margin-bottom: 15px; */
  text-transform: uppercase;
  color: #05246a;
  font-family: "Manrope", sans-serif;
  text-transform: uppercase;
}

.mainastltxtdiv {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* padding-bottom: 30px; */
}

.divimgavg {
  margin-left: 10px;
}

.mrlftppdiv {
  margin-left: 25px;
}

.abt_main_txt h4 {
  font-size: 26px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 600;
  line-height: 36px;
  font-family: "Manrope", sans-serif;
  color: #000;
}

.abt_main_txt p {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 600;
  line-height: 17px;
  font-family: "Manrope", sans-serif;
  color: #000;
}

.aboutmaindivflx {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
}

.about_txt_dtls_div {
  width: 100%;
  max-width: 40%;
}

.about_img_dtls_div {
  width: 100%;
  max-width: 48%;
}

.abtvctdivimg {
  width: 100%;
  height: 100%;
  /* max-width: 85%; */
  /* position: absolute; */
}

.abtvctdivimg img {
  width: 100%;
  height: 345px;
  border-radius: 10px;
}

.abtallimgdiv {
  position: relative;
  max-width: 666px;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 180px; */
}

.expert-team-one {
  -webkit-animation: move-bottom 4s linear infinite;
  animation: move-bottom 3s linear infinite;
}

@keyframes move-bottom {
  0%,
  100% {
    bottom: 50px;
  }

  50% {
    bottom: 80px;
  }
}

.expert-team {
  right: 0;
  z-index: 1;
  overflow: hidden;
  max-width: 201px;
  position: absolute;
  padding: 40px 17px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.expert-team .number {
  font-size: 30px;
  line-height: 39px;
  color: #fff;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
}

.mb-10 {
  margin-bottom: 10px;
}

.color-white {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
}

.expert-team:after {
  top: 0;
  left: 0;
  content: "";
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 157, 0, 0.486);
}

.expert-team-two {
  left: -53px;
  top: 72px;
  max-width: 233px;
  padding: 20px 13px;
  border-radius: 35px 0px 0px 35px;
}

.expert-team-two:after {
  background: rgba(14, 10, 1, 0.8) !important;
}

.expert-team-three {
  z-index: -1;
  bottom: -110px;
  max-width: 318px;
  padding: 15px 35px 28px;
  border-radius: 0px 0px 50px 50px;
  left: 123px;
}

.expert-team-three:after {
  background: rgba(14, 10, 1, 0.8) !important;
}

.about_txt_spl_div_hh {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  line-height: 22px;
  font-family: "Manrope", sans-serif;
  padding-top: 10px;
}

.about_txt_spl_div_pp {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  font-family: "Manrope", sans-serif;
  padding-bottom: 10px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  height: 93px;
}

.mrn_btndiv {
  margin: 0px 0px 0px 0px;
  max-width: 40%;
  width: 100%;
}

/* Home About End */

/* Home Blog start */
.blg_txthhh h4 {
  font-size: 26px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 600;
  line-height: 36px;
  font-family: "Manrope", sans-serif;
}

.blg_txthhh {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seebtn {
  background-color: #ffffff;
  padding: 8px;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  /* max-width: 65%; */
  transition: all 0.4s ease-in-out;
  z-index: 1;
  overflow: hidden;
  position: relative;
  border: 1px solid #ff9c00;
  /* white-space: nowrap; */
}

.seebtn:hover::before {
  left: 0;
  right: auto;
  transform: scaleX(1);
  transform-origin: left;
}

.seebtn::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  transform: scaleX(0);
  transform-origin: right;
  background: #ff9c00;
  transition: transform 0.5s ease 0s;
  /* border: 1px solid #fff; */
  z-index: -1;
}

.seebtn:hover {
  text-decoration: none;
  color: #fff;
}

.seedivblg {
  width: 100%;
  max-width: 15%;
}

.blgmaindivflx {
  display: flex;
  justify-content: space-between;
  /* gap: 1%; */
  align-items: center;
  padding-top: 10px;
  /* overflow-x: auto; */
}

.blgmaindivflx ::-webkit-scrollbar {
  display: none;
}

.blgmaindivmain {
  width: 100%;
  max-width: 32.2%;
  background: #fff;
  -webkit-box-shadow: 0px 6px 30px rgba(4, 33, 63, 0.04);
  box-shadow: 0px 6px 30px rgba(4, 33, 63, 0.04);
}

.blg_img_hd {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition-property: all;
}

.blg_img_hd img {
  width: 100%;
  height: 300px;
  transition-property: all;
  transition-duration: 500ms;
}

.blg_img_hd:hover img {
  transform: scale(1.2, 1.2);
}

.blgtxtpp {
  font-size: 16px;
  padding-top: 7px;
  padding-bottom: 3px;
  font-weight: 800;
  line-height: 23px;
  font-family: "Manrope", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.blgtxthhh {
  font-size: 17px;
  /* padding-top: 7px; */
  /* padding-bottom: 6px; */
  font-weight: 400;
  line-height: 25px;
  font-family: "Manrope", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  margin: 10px 0px;
  /* height: 80px; */
}

.blgdivtxt {
  padding: 10px;
}

.blog_main_section {
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Home Blog end */

/* Home Testimonial Start */
.tstboxdiv {
  background-color: #d1d2d4;
  -webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 0px 0px 5px 0px #ccc;
  /* border: 1px solid #19313e;  */
  padding: 15px 10px;
}

.testimonial_main_section .slick-slide {
  padding: 0 10px;
}

.tstimgtxt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tst_img {
  width: 100%;
  height: 75px;
  max-width: 78px;
  min-width: 50px;
  border: 1px solid #d9e1f7;
  border-radius: 50%;
}

.tst_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  object-position: center;
}

.tsttxtdivpp {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Manrope", sans-serif;
  /* padding-bottom: 3px; */
  white-space: nowrap;
}

.tsttxtdivppdt {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  font-family: "Manrope", sans-serif;
  /* padding-bottom: 3px; */
  white-space: nowrap;
}

.tsetrating i {
  font-size: 15px;
  margin-right: 6px;
  color: orange;
}
/* .tsetrating{
    white-space: nowrap;
} */

.tsttxtdivhh {
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 7px;
  font-weight: 600;
  line-height: 15px;
  font-family: "Manrope", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 20;
  height: 123px;
}

.txtdiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4%;
  width: 100%;
  padding-left: 20px;
}

.testimonial_main_section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.banner_sld {
  margin-bottom: 40px;
}

/* Home Testimonial End */
@media (max-width: 1399px) {
  /* p.lorText {
        color: #fff;
        font-size: 22px;
        line-height: 30px;
    } */

  p.call_txt {
    color: #fff;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 992px) {
  .expert-team-three {
    left: 45px;
  }

  .srvcd_txtr h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .srvc_img {
    width: 87px;
    height: 88px;
    /* overflow: hidden; */
    transition-property: all;
    margin: auto;
  }

  .srvcndiv {
    padding: 15px 20px 9px 20px;
  }

  .srvctxtppp {
    font-size: 17px;
  }
}

@media (max-width: 991px) {
  .blgmaindivmain {
    min-width: 45%;
  }

  .blgmaindivflx {
    gap: 2%;
  }

  .banner_sld {
    margin-bottom: 20px;
  }

  .srvc_main_section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .about_main_section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .blog_main_section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .testimonial_main_section {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .txtdiv {
    gap: 5%;
    width: 100%;
    padding-left: 8px;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  p.lorText {
    font-size: 19px;
    line-height: 26px;
  }

  p.lorText {
    font-size: 19px;
    line-height: 26px;
  }

  .banner_sld {
    height: 370px;
    width: 100%;
  }

  .aboutmaindivflx {
    flex-wrap: wrap;
  }

  .about_txt_dtls_div {
    width: 100%;
    max-width: 100%;
  }

  .about_img_dtls_div {
    width: 100%;
    max-width: 100%;
  }

  .expert-team-two {
    left: 109px;
    top: -54px;
    max-width: 424px;
    padding: 15px 15px;
    border-radius: 35px 0px 0px 35px;
  }

  .expert-team-three {
    z-index: -1;
    bottom: -109px;
    max-width: 530px;
    padding: 15px 35px 28px;
    border-radius: 0px 0px 50px 50px;
    left: 75px;
  }

  .abtallimgdiv {
    margin-bottom: 115px;
    margin-top: 70px;
  }

  .expert-team {
    padding: 25px 15px;
  }

  .srvc_sct_bg {
    width: 100%;
    /* min-width: 500px; */
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  .srvc_sct_bg::-webkit-scrollbar {
    display: none;
  }

  .srvc_sct_bg {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .srvc_box_main {
    width: 100%;
    min-width: 260px;
    margin-right: 8px;
  }

  .blgmaindivflx {
    width: 100%;
    /* min-width: 500px; */
    overflow-x: auto;
  }

  .blgmaindivflx::-webkit-scrollbar {
    display: none;
  }

  .blgmaindivflx {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .blgmaindivmain {
    width: 100%;
    min-width: 350px;
    margin-right: 8px;
  }

  .seedivblg {
    width: 100%;
    max-width: 25%;
  }

  .tphdrdivul {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2%;
  }

  .tphdrdivul li.tphdrdivli {
    padding-top: 10px;
  }

  .tsttxtdivpp {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    font-family: "Manrope", sans-serif;
    padding-bottom: 3px;
  }

  .tsetrating i {
    font-size: 10px;
    margin-right: 6px;
    color: orange;
  }

  .banner_sld .slick-prev:before {
    font-size: 14px !important;
    width: 32px;
    height: 32px;
  }

  .textheadimgbaner {
    font-size: 30px;
    font-weight: 800;
    line-height: 44px;
    max-width: 500px;
  }

  .srvc_sct_bg {
    padding-top: 20px;
    justify-content: flex-start;
  }

  /* .srvc_main_section {
        padding-top: 20px;
        padding-bottom: 10px;
    } */
  .aboutmaindivflx {
    padding-top: 5px;
  }

  .about_txt_spl_div_pp {
    -webkit-line-clamp: 2;
    height: 50px;
  }

  .abt_main_txt p {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 23px;
  }

  .about_txt_spl_div_hh {
    padding-top: 5px;
  }

  /* .about_main_section {
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .blog_main_section {
        padding-top: 20px;
        padding-bottom: 10px;
    } */
}

@media (max-width: 577px) {
  p.lorText {
    font-size: 15px;
    line-height: 23px;
  }

  p.call_txt {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
  }

  .bktanttdy {
    padding: 8px;
    font-size: 12px;
  }

  .banner_sld {
    height: 290px;
    width: 100%;
  }

  .textheadimgbaner {
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    line-height: 27px;
    width: 100%;
    /* max-width: 230px; */
    font-family: "Manrope", sans-serif;
  }
  /* 
    .lghdiv {
        min-width: 40px;
        height: 26px;
        width: 54px;
    } */

  .srvctxtppp {
    font-size: 18px;
    height: 41px;
  }

  .abt_main_txt h4 {
    font-size: 20px;
    line-height: 27px;
  }

  .about_txt_spl_div_hh {
    font-size: 15px;
    line-height: 20px;
    padding-top: 5px;
  }

  .about_txt_spl_div_pp {
    line-height: 19px;
    padding-bottom: 5px;
  }

  .abt_main_txt p {
    line-height: 21px;
  }

  .about_main_section {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .abtvctdivimg img {
    height: 280px;
  }

  .expert-team-two {
    top: -69px;
  }

  .blg_txthhh h4 {
    font-size: 22px;
    line-height: 30px;
  }

  .seebtn {
    padding: 6px;
    font-size: 12px;
  }

  .mre_btn {
    padding: 4px;
    font-size: 13px;
    width: 100%;
  }

  .ftr_email_mn input {
    height: 36px;
  }

  .email_send {
    padding: 7px;
  }

  /* 
    .srvc_img {
        height: 143px;
    } */

  .srvcd_txtr h4 {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 35px;
  }

  .srvcd_txtr p {
    height: 93px;
  }

  .mre_btn_divv {
    margin: 8px 0;
  }

  .srvcd_txtr {
    padding: 6px;
  }

  .blgtxthhh {
    font-size: 13px;
    padding-top: 3px;
    padding-bottom: 4px;
    line-height: 19px;
    -webkit-line-clamp: 10;
  }

  .blgtxtpp {
    font-size: 14px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 600;
    line-height: 17px;
  }

  .dateDay {
    font-size: 15px;
  }

  .dateMonth {
    font-size: 13px;
  }

  .dateYear {
    font-size: 13px;
  }

  .subscribe_main_section {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .testimonial_main_section {
    padding-top: 5px;
    padding-bottom: 15px;
  }

  .blog_main_section {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .srvc_main_section {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .abt_div_txt {
    font-size: 14px;
    line-height: 23px;
    /* padding-bottom: 7px;
        margin-bottom: 4px; */
  }

  .blg_txthhh h4 {
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 4px;
    font-weight: 600;
    line-height: 25px;
  }

  .srvc_box_main {
    margin-bottom: 5px;
  }

  .srvc_sct_bg {
    padding-top: 10px;
  }
  .tst_img {
    width: 100%;
    height: 58px;
    max-width: 62px;
    min-width: 46px;
    border: 1px solid #d9e1f7;
    border-radius: 50%;
  }

  /* 
    .mainastltxtdiv {
        padding-bottom: 15px;
    } */
}

@media (max-width: 481px) {
  p.lorText {
    font-size: 11px;
    line-height: 19px;
    padding-bottom: 3px;
    max-width: 310px;
  }

  .bktanttdyDiv {
    margin: 6px 0;
  }

  .banner_sld .slick-prev:before {
    font-size: 13px !important;
    width: 25px;
    height: 26px;
  }

  p.call_txt {
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    padding-top: 3px;
    padding-bottom: 4px;
  }

  .bktanttdy {
    padding: 6px;
    font-size: 11px;
  }

  .banner_sld {
    height: 214px;
  }

  .bg_overlay {
    width: 100%;
    height: 100%;
    background-color: #0f334682;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 13px;
    max-height: 69%;
  }

  .srcvgdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 9px;
  }

  .txtsbscrd {
    font-size: 16px;
    letter-spacing: 1px;
    padding-bottom: 8px;
  }

  .txtsbscrdpp {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    padding-bottom: 10px;
  }

  /* .email_send {
        max-width: 25%;
    } */
  /* 
    .ftr_email_mn input {
        max-width: 183px;
    } */

  .blg_txthhh h4 {
    font-size: 15px;
    line-height: 20px;
  }

  .seedivblg {
    width: 100%;
    max-width: 37%;
  }

  .srvc_box_main {
    width: 100%;
    min-width: 250px;
    margin-right: 8px;
  }

  .blgmaindivmain {
    width: 100%;
    min-width: 248px;
    margin-right: 8px;
  }

  .blg_img_hd img {
    width: 100%;
    height: 170px;
    transition-property: all;
    transition-duration: 500ms;
  }
  .textheadimgbaner {
    margin-bottom: 0;
  }
  .banner_dtls_txt {
    padding-top: 45px;
    padding-bottom: 10px;
  }
}

@media (max-width: 399px) {
  .banner_sld .slick-prev {
    left: 79%;
    top: 70%;
  }
}
