.imigratebrdrdiv {
    border: 2px solid #19313e;
    padding: 10px 15px;
    width: 100%;
    /* margin-top: 20px; */
}

.imigratebrdrdivdtls {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: column;

}

.imgdivbrdr {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itmtxt {
    color: #000;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    font-size: 15px;
    line-height: 22px;
}

.imgdivbrdr {
    overflow: hidden;
    z-index: 1;
    position: relative;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.imgdivbrdr::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: auto;
    transform: scaleX(0);
    transform-origin: right;
    background: #19313e;
    transition: transform 0.5s ease 0s;
    z-index: -1;
}

.imgdivbrdr:hover::before {
    left: 0;
    right: auto;
    transform: scaleX(1);
    transform-origin: left;
}

.imgdivbrdr:hover .arrwicn {
    text-decoration: none;
    color: #fff;
}

.imgdivbrdr:hover .itmtxt {
    text-decoration: none;
    color: #fff;
}

.immigrateimgmain {
    width: 100%;
    height: auto;
    /* margin-top: 20px;
    margin-bottom: 5px; */
}

.immigrateimgmain img {
    width: 100%;
    height: 400px
}

.imgrtdttxt {
    color: #000;
    font-size: 30px;
    font-weight: 800;
    font-family: 'Manrope', sans-serif;
    line-height: 38px;
    /* padding-top: 5px;
    padding-bottom: 2px; */
}

.imgtxtdivhh {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Manrope', sans-serif;
    line-height: 28px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.allctctdiv {
    padding: 30px 0px;
    width: 100%;
    height: auto;
}

.anscitip {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    line-height: 22px;
    padding-top: 10px;
    color: #000;
}

.lstctctpp {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    line-height: 19px;
    padding-top: 10px;
    color: #000;
    font-weight: 600;
}

.imgctctdiv {
    width: 100%;
    height: 100%;
    /* max-width: 49%; */
}

.divimctct {
    width: 100%;
    height: 350px;
    position: relative;
}

.antdivxallpost {
    position: absolute;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #00000034; */
    width: 100%;
    height: 100%;
    opacity: 0;
    transition-property: all;
    transition-duration: 500ms;

}

.antdivxallpost:hover {
    opacity: 1;
    cursor: pointer;
    background-color: #000000a1;
}

/* .antdivxallpost:hover .divimctct{
    transform: scale(1.2, 1.2);
} */
.stcdivstylani {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}

.alllistdiv {
    width: 100%;
    height: 100%;
    /* padding: 20px 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
}

.divimctct img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imgstdnt {
    padding-top: 54px;
    width: 100%;
    height: 250px;
}

.imgstdntabctg {
    width: 100%;
    height: 250px;
}

.imgstdntabctg img {
    width: 100%;
    height: 100%;
}

.imgstdnt img {
    width: 100%;
    height: 250px;
}

.hwtxtbcdiv {
    font-family: 'Playfair Display', serif;
    font-size: 38px;
    font-weight: 600;
    padding-bottom: 15px;
    font-style: italic;
    /* padding-top: 20px; */
}

.divtxtflx {
    width: 100%;
    max-width: 48%;
}

.frmdiv {
    width: 100%;
    max-width: 48%;
}

.bgimgdiv {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 450px;
    background-repeat: no-repeat;
    padding: 20px;
    margin: 30px 0px;
}

.txtppp {
    font-family: 'Playfair Display', serif;
    font-size: 38px;
    font-weight: 600;
    padding-bottom: 15px;
    font-style: italic;
    line-height: 45px;
    color: #fff;
}

.divcttxtpp {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    line-height: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.divtxtpppifd {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    line-height: 25px;
    padding-top: 10px;
}

/* .txtppp{
   color: #fff;
   font-size: 40px;
} */
.ctctxtpp {
    list-style-type: disc;
    font-size: 13px;
    line-height: 19px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    color: #000;
}

.allllist {
    margin-left: 30px;
}

.alllistppptxt {
    font-size: 15px;
    line-height: 22px;
    font-weight: 800;
    font-family: 'Manrope', sans-serif;
    padding-bottom: 10px;
}

.alllistdivft {
    padding-bottom: 10px;
}

.rdyapplyflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.sndbtnmain {
    position: relative;
    /* margin: 15px 0; */
    width: 100%;
    max-width: 200px;
    margin: auto;
}

.sndbthn {
    background-color: #ffffff;
    padding: 8px;
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    border-radius: 5px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    max-width: 65%;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 1;
    overflow: hidden;
    position: relative;
    border: 1px solid #FF9C00;
}

.sndbthn:hover::before {
    left: 0;
    right: auto;
    transform: scaleX(1);
    transform-origin: left;
}

.sndbthn::before {
    content: "";
    border-radius: inherit;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: auto;
    right: 0;
    transform: scaleX(0);
    transform-origin: right;
    background: #FF9C00;
    transition: transform 0.5s ease 0s;
    /* border: 1px solid #fff; */
    z-index: -1;
}

.sndbthn:hover {
    text-decoration: none;
    color: #fff;
}

.txtareclss textarea {
    width: 100%;
    resize: none;
    padding: 10px;
}

.txtareclss textarea:focus {
    outline: 0;
    border: none;
}

.frstclss input:focus {
    outline: 0;
    border: none;
    box-shadow: none;
}

.cndntxtpp {
    color: #000;
    font-size: 30px;
    font-weight: 800;
    font-family: 'Manrope', sans-serif;
    line-height: 38px;
    ;
}

.cndtxtdiv {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    line-height: 22px;
    padding-top: 10px;
    color: #000;
}

.cndntxtppcntpp {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Manrope', sans-serif;
    line-height: 28px;
    padding-top: 10px;
}

.wystxtpp {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    line-height: 21px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.spnbvtxt {
    color: #000;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Manrope', sans-serif;
    line-height: 22px;
}

.exptxtpp {
    padding: 5px 0px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    line-height: 20px;
}

.ulxctctfg {
    margin-left: 20px;
}
.txtothprgms{
    font-size: 18px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    line-height: 24px;
    font-weight: 800;
}
.othprmntdiv{
    padding: 15px 0px;
}
.ullistpp{
    margin-left: 30px;
}
.othlstdipp{
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    line-height: 20px;
    padding-top: 10px;
}
.othprmntdivflx{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}
.othprmntdiv{
    width: 100%;
    max-width: 48%;
    box-shadow: 0px 0px 5px 0px #ccc;
    background-color: #fff;
    padding: 10px;
}
.cndivdffg{
    width: 100%;
    max-width: 48%;
    box-shadow: 0px 0px 5px 0px #ccc;
    background-color: #fff;
    padding: 10px; 
}
.cndatrpp{
    font-size: 18px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    line-height: 24px;
    font-weight: 800;
    padding-bottom: 10px;
}
.ptxtcppas{
    font-weight: 600;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    line-height: 20px;   
    color: #000;
}

.prmntrsd{
    padding-top: 20px;
}
.pscvyu{
    font-weight: 600;
    font-size: 18px;
    font-family: 'Manrope', sans-serif;
    line-height: 25px;   
}
.residntmain{
    width: 100%;
    height: auto;
    padding: 20px 0px;
}
.exprsgg{
    box-shadow: 0px 0px 5px 0px #ccc;
    background-color: #fff;
    width: 100%;
    padding: 20px;
}
.txtghprnst{
    font-size: 16px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    line-height: 22px;   
    padding-top: 10px;
    padding-bottom: 10px;
}
.cbngfhyt{
    margin-left: 25px;
}
.ulendfgg{
    margin-left: 40px 
}
.istxyvv{
    font-size: 15px;
    font-weight: 700;
    font-family: 'Manrope', sans-serif;
    color: #000;
    line-height: 22px;
    padding-top: 5px;
}
.gghbhtxt{
    font-size: 15px;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
    color: #000;
    line-height: 22px;
    padding-top: 5px; 
}
@media(max-width:992px) {
    .anscitip {
        font-size: 13px;
        line-height: 19px;
        color: #000;
    }

    .lstctctpp {
        font-size: 12px;
        line-height: 17px;
    }
}

@media(max-width:767px) {
    .imigratebrdrdiv {
        margin-top: 25px;
    }

    .alllistdiv {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .rdyapplyflx {
        flex-wrap: wrap;
    }

    .divtxtflx {
        width: 100%;
        max-width: 100%;
    }

    .frmdiv {
        width: 100%;
        max-width: 100%;
        padding-top: 10px;
    }

    .bgimgdiv {
        padding: 13px;
        margin-top: 10px;
        height: auto;
    }

    .txtppp {
        padding-bottom: 4px;
    }
    .othprmntdivflx{
       flex-wrap: wrap;
    }
    .othprmntdiv{
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    .cndivdffg{
        width: 100%;
        max-width: 100%;
    }
    .cndntxtpp {
        font-size: 26px;
        line-height: 32px;
    }
    .cndtxtdiv {
        font-size: 15px;
        line-height: 20px;
    }
    .cndntxtppcntpp {
        font-size: 17px;
        line-height: 22px;
    }
    .spnbvtxt {
        color: #000;
        font-size: 13px;
        line-height: 19px;
    }
    .txtothprgms {
        font-size: 14px;
        line-height: 21px;
        font-weight: 800;
    }
    .cndatrpp {
        font-size: 14px;
        line-height: 21px;
        font-weight: 800;
    }
    .pscvyu {
        font-size: 14px;
        line-height: 21px;
        font-weight: 800;
    }
}

@media(max-width:576px) {
    .divtxtpppifd {
        font-size: 14px;
        line-height: 20px;
        padding-top: 5px;
    }

    .divcttxtpp {
        font-size: 14px;
        line-height: 20px;
        padding-top: 5px;
    }

    .txtppp {
        font-size: 30px;
        line-height: 35px;
        padding-bottom: 10px;
    }

    .hwtxtbcdiv {
        font-size: 23px;
        padding-bottom: 11px;
    }

    .alllistppptxt {
        font-size: 13px;
        line-height: 18px;
        padding-bottom: 10px;
    }

    .ctctxtpp {
        font-size: 12px;
        line-height: 17px;
    }

    .imgrtdttxt {
        font-size: 22px;
        line-height: 30px;
    }

    .allctctdiv {
        padding: 20px 0px;
    }

    .bgimgdiv {
        margin: 20px 0px;
    }
    .cndntxtpp {
        font-size: 20px;
        line-height: 27px;
    }
    .cndtxtdiv {
        font-size: 14px;
        line-height: 19px;
    }
}