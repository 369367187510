.abouttext_area {
    width: 100%;
    height: auto;
    padding: 40px 0 20px;
}

.abouttext_area .sub_heading {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    padding-bottom: 9px;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: #05246a;
}

.abouttext_area .sub_heading:before {
    position: absolute;
    content: '';
    width: 70px;
    height: 2px;
    left: 0px;
    bottom: 0px;
    background-color: #05246a;
}

.abouttext_area .heading {
    position: relative;
    display: block;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: #142b3c;
    margin: 15px 0px 10px 0px;
    transition: all 500ms ease;
}

.abouttext_area .text {
    font-size: 16px;
    color: #828080;
    line-height: 26px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
}

/**ourTeam_sec**/
.ourTeam_sec {
    width: 100%;
    height: auto;
    padding: 20px 0;
}

.ourTeam_sec .heding {
    position: relative;
    display: block;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: #142b3c;
    margin: 0px 0px 10px 0px;
    transition: all 500ms ease;
}

.ourTeam_sec .text {
    font-size: 16px;
    color: #828080;
    line-height: 26px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
}

/**ourTeam_boxcard**/

.ourTeam_boxcard {
    width: 100%;
    height: auto;
    padding: 20px 0 40px 150px;
}

.ourTeam_boxcard .content_wrap {
    width: 100%;
    height: auto;
    background-color: #343436;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ourTeam_boxcard .leftside {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
    height: 350px;
    overflow: hidden;
    margin-left: -150px;
}

.ourTeam_boxcard .leftside img {
    width: 100%;
    height: 100%;
}

.ourTeam_boxcard .rightside {
    width: 100%;
    max-width: 100%;
    min-width: 70%;
    height: auto;
    padding: 60px;
}

.ourTeam_boxcard .head {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    line-height: normal;
    color: #fff;
}

.ourTeam_boxcard .sub_head {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
    line-height: normal;
    padding: 0;
}

.ourTeam_boxcard .text {
    font-size: 16px;
    color: #a09d9d;
    line-height: 26px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
}

/**missonVisson_sec**/
.missonVisson_sec {
    width: 100%;
    height: auto;
    padding: 0px 0 20px;
}

.missonVisson_sec .heding {
    display: block;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: #142b3c;
    margin: 0px 0px 10px 0px;
    transition: all 500ms ease;
}
.text{
    padding-bottom: 10px;
}
/* .missonVisson_sec .text {
    font-size: 16px;
    color: #828080;
    line-height: 26px;
    font-weight: 400;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
} */

.missonVisson_sec .main_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
}
.allmiision:nth-child(odd) {
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: space-between;
    padding-bottom: 10px;
  }
.allmiision:nth-child(even) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
.missonVisson_sec .missson_wrap {
    width: 100%;
    max-width: 46%;
    min-width: 46%;
    height: auto;
    /* background-color: #343436; */
    /* padding: 40px; */
    color:#828080;
    font-family: 'Manrope', sans-serif;
}

.missonVisson_sec .missson_wrap .misshead {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    line-height: normal;
    color: #000000;
    position: relative;
    padding-bottom: 7px;

}
.missonVisson_sec .missson_wrap .misshead:after{
    position: absolute;
    content: '';
    width: 84px;
    height: 3px;
    left: 0px;
    bottom: 0px;
    background-color: #05246a;
    margin-top: 15px;
}
.misssonimgdiv{
    width: 100%;
    max-width: 48%;
    height: 100%;
}
.misssonimgmain{
    width: 100%;
    height: 100%;
}
.misssonimgmain img{
    width: 100%;
    height: 300px;
    border-radius: 10px;
}

.missonVisson_sec .missson_wrap .text {
    font-size: 16px;
    color: #000000;
    line-height: 26px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    height: 260px;
}


.missonVisson_sec .visson_wrap {
    width: 100%;
    max-width: 50%;
    min-width: 50%;
    height: auto;
    background-color: #142b3c;
    padding: 40px;
}

.missonVisson_sec .visson_wrap .visshead {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    line-height: normal;
    color: #fff;
}

.missonVisson_sec .visson_wrap .text {
    font-size: 16px;
    color: #a09d9d;
    line-height: 26px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
}


@media (max-width: 767px) {
    .ourTeam_boxcard {
        padding: 0;
    }

    .ourTeam_boxcard .content_wrap {
        flex-wrap: wrap;
        padding: 20px;
    }

    .ourTeam_boxcard .leftside {
        width: 100%;
        max-width: 40%;
        min-width: 40%;
        height: 270px;
        overflow: hidden;
        margin: 0;
    }

    .ourTeam_boxcard .rightside {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
        padding: 10px 0;
    }
    .allmiision:nth-child(odd) {
        flex-wrap: wrap;
    }
    .missonVisson_sec .missson_wrap {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
    }
    .misssonimgdiv {
        width: 100%;
        max-width: 100%;
        height: 100%;
    }
    .misssonimgmain img{
        height: 300px;
    }
    .missonVisson_sec .missson_wrap .text {
        -webkit-line-clamp: 7;
        height: 188px;
    }
    .allmiision:nth-child(even) {
        flex-wrap: wrap;
    }
}

@media (max-width: 567px) {
 
    .ourTeam_boxcard .leftside {
        width: 100%;
        max-width: 50%;
        min-width: 50%;
        height: 270px;
    }

    .abouttext_area .heading {
        font-size: 20px;
        margin-bottom: 5px;
        line-height: normal;
    }

    .abouttext_area .text {
        font-size: 14px;
    }

    .missonVisson_sec .heding {
        font-size: 20px;
        margin-bottom: 5px;
        line-height: normal;
    }

    .missonVisson_sec .text {
        font-size: 14px;
    }

    .missonVisson_sec .main_wrap {
        flex-wrap: wrap;
    }

    .missonVisson_sec .missson_wrap {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
        /* padding: 15px 15px; */
    }

    .missonVisson_sec .visson_wrap {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
        padding: 15px 15px;
    }
    .missonVisson_sec .missson_wrap .misshead {
         font-size: 20px;
    }
    .missonVisson_sec .visson_wrap .visshead {
        font-size: 20px;
    }

    .ourTeam_sec .heding {
        font-size: 20px;
        margin-bottom: 5px;
        line-height: normal;
    }

    .ourTeam_sec .text {
        font-size: 14px;
    }

    .ourTeam_boxcard .head {
        font-size: 18px;
    }

    .ourTeam_boxcard .sub_head {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .ourTeam_boxcard .text {
        font-size: 14px;
        line-height: normal;
    }
    
}

@media (max-width: 480px) { 
    .ourTeam_boxcard .leftside {
        width: 100%;
        max-width: 80%;
        min-width: 80%;
        height: 270px;
    }
}

@media (max-width: 420px) { 
    .ourTeam_boxcard .leftside {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
    }
}