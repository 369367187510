.innerBanner_sec {
    width: 100%;
    height: auto;
    padding: 100px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.innerBanner_sec .overlay {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background-color: #000000a3;
   z-index: 1;
}

.innerBanner_sec .content_wrap {
   position: relative;
   width: 100%;
   height: 220px;
   z-index: 2;
}

.innerBanner_sec .head {
    font-size: 40px;
    line-height: 50px;
    color: #fff;
    font-weight: 800;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.breadcrumb {
    background-color: transparent;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
}

.breadcrumb ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .innerBanner_sec .content_wrap {
        height: 200px;
    }
}

@media (max-width: 480px) {
    .innerBanner_sec .head {
        font-size: 26px;
        font-weight: 700;
    }
    .innerBanner_sec .content_wrap {
        height: 150px;
    }
    .innerBanner_sec {
        width: 100%;
        height: auto;
        padding: 20px 0;
    }
}