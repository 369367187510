.loader-modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10000;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.loader-modal-content {
    /* background-color: #fefefe; */
    /* margin: auto; */
    margin-top: 10em;
/* color: aqua; */
    padding: 20px;
    /* border: 1px solid #888; */
    width: 100%;
    display: flex;
    justify-content: center;
}

/* The Close Button */
.loader-close {
    color: #aaaaaa;
    float: right;
    font-size: 38px;
    font-weight: bold;
}

.loader-close:hover,
.loader-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}


.white-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9999; /* Ensure the loader is on top of everything */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
