.blogDetailSection {
    margin-bottom: 80px;
}

.blgMainDiv {
    border: 1px solid rgba(165, 159, 159, 0.151);
    margin-bottom: 15px;
}

.dateInDiv {
    display: inline-block;
    text-align: center;
    padding: 5px 13px 12px 13px;
    border-radius: 0 0 8px 8px;
    background-color: #19313e;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 2;
}

.dateDay {
    font-size: 22px;
    font-weight: bolder;
    color: #fff;
    margin: 0;
    line-height: normal;
}

.dateMonth {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin: 0;
    line-height: normal;
}

.dateDayMonDiv {
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ffffffb9;
}

.dateYear {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin: 0;
    line-height: normal;
}

.blogDetailFig {
    width: 100%;
    height: 400px;
    margin: 0;
}

.blogDetailFig img {
    width: 100%;
    height: 100%;
}

.blogDetailText {
    padding: 30px;
    border: 1px solid rgba(189, 180, 180, 0.404);
}

.conslHead {
    color: #243342;
    text-transform: capitalize;
    margin: 0 0 20px 0;
    font-size: 31px;
    font-weight: 700;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.consDetailPara {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 20px 0;
}

.dbleQuoteText {
    font-size: 45px;
    color: #c39644;
    margin: 0;
}

.quotePara {
    font-size: 16px;
    line-height: 30px;
    font-family: "Poppins", sans-serif;
    font-style: italic;
    margin: 0 0 20px 0;
}

.richName {
    color: #0E1E36;
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px;
}

.richCeo {
    color: #243342;
    font-size: 17px;
    font-style: italic;
    font-weight: 500;
}

.richLeft {
    color: #243342;
    font-size: 17px;
    font-weight: 500;
    margin-right: 3px;
}

.richTextDiv {
    margin-bottom: 25px;
}

.richAfterPara {
    font-size: 16px;
    color: #243342;
    margin: 0 0 25px 0;
}

.blogSmFig {
    width: 48.5%;
    height: 280px;
    margin: 0;
}

.blogSmFig img {
    width: 100%;
    height: 100%;
}

.blogSmFigDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.blogSmAfterPara {
    font-size: 16px;
    color: #243342;
    margin: 0 0 16px 0;
}

.blogAftBtn {
    background-color: #EFF0F6;
    border: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    padding: 8.5px 20.1px;
    border-radius: 4px;
    color: #dab057;
    transition-property: all;
    transition-duration: 450ms;
}

.blogAftBtn:hover {
    background-color: #c39644;
    color: #fff;
}

.blogAfBtnsDiv {
    display: inline-block;
    max-width: 305px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blogSocialLi {
    list-style-type: none;
    width: 40px;
    height: 40px;
    background-color: #EFF0F6;
    border-radius: 50%;
    cursor: pointer;
}

.blogSocialUl {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 180px;
    width: 100%;
}

.blogAftSociDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
}

.blogSociLink {
    color: #dab057;
    display: inline-block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-duration: 450ms;
    border-radius: 50%;
    font-size: 18px;
}

.blogSociLink:hover {
    text-decoration: none;
    background-color: #c39644;
    color: #fff;
}

.twoCommnText {
    font-size: 25px;
    font-weight: 600;
    margin: 0;
}

.twoCommnTextDiv {
    padding-bottom: 18px;
    border-bottom: 1px solid #ccd2e6;
}

.cmntImgFig {
    width: 75px;
    min-width: 75px;
    height: 75px;
    margin: 0;
    border-radius: 50%;
}

.cmntImgFig img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.mdNameText {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 8px 0;
}

.mdDateText {
    font-size: 15px;
    font-weight: 500;
    color: #243342;
    margin: 0 0 7px 0;
}

.mdParaText {
    font-size: 15px;
    font-weight: 400;
    margin: 0 0 8px 0;
}

.mdReplyBtn {
    background-color: transparent;
    border: 0;
    font-size: 16px;
    font-weight: 600;
    color: #0E1E36;
    transition-property: all;
    transition-duration: 500ms;
}

.mdReplyBtn:hover {
    color: #c39644;
}

.mdCmntMainDiv {
    display: flex;
    padding: 40px 0;
    border-bottom: 1px solid #ccd2e6;
}

.mdAllTextsDiv {
    padding-left: 13px;
}

.cardCmtDiv {
    margin-bottom: 25px;
}

.seacrDiv {
    background-color: #EFF0F6;
    padding: 30px 30px 40px 30px;
    width: 100%;
    margin-bottom: 30px;
}

.searchHereHead {
    color: #0E1E36;
    font-size: 28px;
    font-weight: 500;
    margin: 0 0 18px 0;
}

.serachInp {
    width: 80%;
    background-color: #fff;
    font-size: 16px;
    color: #243342;
    padding: 13px 15px;
    outline: none;
    border: 0;
}

.seacrBtn {
    width: 20%;
    padding: 12px 0;
    border: 0;
    background-color: #ece0c8;
    color: #c39644;
    transition-property: all;
    transition-duration: 500ms;
    font-size: 18px;
}

.seacrBtn:hover {
    background-color: #dab057;
    color: #fff;
}

.resentPostDiv {
    background-color: #EFF0F6;
    padding: 30px 30px 40px 30px;
    width: 100%;
    max-height: 1985px;
}

.resentPostDiv::-webkit-scrollbar {
    display: none;
}

.resentBlogDiv {
    width: 100%;
    height: 250px;
    position: relative;
}

.resentBlogFig {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
}

.resentBlogFig img {
    width: 100%;
    height: 100%;
    transition-property: all;
    transition-duration: 400ms;
}

.resentBlogDiv:hover .resentBlogFig img {
    transform: scale(1.2, 1.2);
}

.resentOverlayDiv {
    width: 100%;
    height: 100%;
    background-color: #0000003d;
    position: absolute;
    top: 0;
    left: 0;
}

.twlJunText {
    font-size: 15px;
    color: #fff;
    margin-bottom: 8px;
    cursor: pointer;
    transition-property: all;
    transition-duration: 500ms;
}

.twlJunText span {
    font-weight: 600;
}

.twlJunText .clkSpan {
    margin-right: 7px;
}

.twlJunText:hover {
    color: #FF9C00;
}

.twlAftText {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    transition-property: all;
    transition-duration: 500ms;
}

.twlAftText:hover {
    color: #FF9C00;
}

.twlMainDiv {
    padding: 20px;
    position: absolute;
    left: 0;
    bottom: 15px;
}

.resentAllBlgDiv {
    margin-bottom: 15px;
}

.morePostBtn {
    width: 100%;
    padding: 14px 0;
    border: 0;
    border-radius: 4px;
    background-color: #4069FF;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    transition-property: all;
    transition-duration: 500ms;
    display: inline-block;
    text-align: center;
}

.morePostBtn i {
    margin-right: 5px;
}

.morePostBtn:hover {
    background-color: #FF9C00;
    text-decoration: none;
    color: #fff;
}

@media (max-width: 991px) {
    .blogDetailSection {
        margin-bottom: 35px;
    }

    .resentPostDiv {
        max-height: auto;
    }
}

@media (max-width: 767px) {
    .blogDetailFig {
        height: 310px;
    }

    .blogSmFig {
        height: 225px;
    }

    .blogAfBtnsDiv {
        max-width: 294px;
    }

    .blogSocialUl {
        max-width: 175px;
    }

    .blogSocialLi {
        width: 35px;
        height: 35px;
    }

    .cmntImgFig {
        width: 65px;
        min-width: 65px;
        height: 65px;
    }
}

@media (max-width: 575px) {
    .blogDetailFig {
        height: 275px;
    }

    .conslHead {
        margin: 0 0 15px 0;
        font-size: 25px;
    }

    .dbleQuoteText {
        font-size: 40px;
    }

    .richTextDiv {
        margin-bottom: 10px;
    }

    .blogSmFig {
        height: 185px;
    }

    .blogAfBtnsDiv {
        max-width: 100%;
    }

    .blogSocialUl {
        max-width: 100%;
        justify-content: space-around;
        margin-top: 30px;
    }

    .blogAftSociDiv {
        display: block;
    }

    .twoCommnText {
        font-size: 22px;
    }
}

@media (max-width: 479px) {
    .blogDetailFig {
        height: 215px;
    }

    .conslHead {
        margin: 0 0 10px 0;
        font-size: 16px;
    }

    .blogDetailText {
        padding: 20px;
    }

    .consDetailPara {
        font-size: 12px;
        margin: 0 0 10px 0;
    }

    .dbleQuoteText {
        font-size: 25px;
    }

    .quotePara {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 10px 0;
    }

    .richName {
        font-size: 16px;
        margin-right: 10px;
    }

    .richLeft {
        font-size: 15px;
    }

    .richCeo {
        font-size: 15px;
    }

    .richAfterPara {
        font-size: 12px;
        margin: 0 0 15px 0;
    }

    .blogSmFig {
        height: 165px;
    }

    .blogSmAfterPara {
        font-size: 12px;
    }

    .blogSmFigDiv {
        margin-bottom: 12px;
    }

    .blogAftBtn {
        font-size: 12px;
        padding: 6.5px 15.1px;
    }

    .twoCommnTextDiv {
        padding-bottom: 12px;
    }

    .twoCommnText {
        font-size: 18px;
    }

    .mdCmntMainDiv {
        padding: 20px 0;
    }

    .cmntImgFig {
        width: 60px;
        min-width: 60px;
        height: 60px;
    }

    .mdNameText {
        font-size: 15px;
        margin: 0 0 2px 0;
    }

    .mdDateText {
        font-size: 13px;
        margin: 0 0 3px 0;
    }

    .mdParaText {
        font-size: 12px;
        margin: 0 0 8px 0;
    }

    .cardCmtDiv {
        margin-bottom: 5px;
    }

    .seacrDiv {
        margin-bottom: 15px;
        padding: 25px 25px 35px 25px;
    }

    .searchHereHead {
        font-size: 19px;
        margin: 0 0 8px 0;
    }

    .serachInp {
        font-size: 15px;
    }

    .resentBlogDiv {
        height: 215px;
    }
}